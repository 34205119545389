<template>
  <div class="choose-time">

      <date-picker v-if="!$isEmpty(dates)"
        v-model='selected.date'
        mode="dateTime"
        :minute-increment="15"

        :attributes="attrs"
        :min-date="new Date()">
        <template #default="{ inputValue, togglePopover, hidePopover }">

           <div
             v-for="(date, i) in dates"
             :key="`time-${i}`"
             class="date-container mb-3"
             :class="[{'primary': !$isUndefined(canASAP) || !$isEmpty(canASAP)}, `time-${i}`]"
             ref="time_button"
             @click.stop="dateSelected($event, date, togglePopover)"
           >
           <template v-if="!$isUndefined(canASAP) || !$isEmpty(canASAP)">
             <div class="d-flex align-center justify-space-between dates ml-2 grey lighten-4 py-3">
               <div class="flex-grow-0 date text-center mr-4 pl-3">
                 <div class="month">{{$date(date.date).local().format("MMM")}}</div>
                 <div class="day">{{$date(date.date).local().format("D")}}</div>
                 <!-- {{$date(date.date).format("YYYY")}} -->
               </div>
               <div class="flex-grow-1 px-2" @click.stop="dateSelected($event, date, togglePopover)">
                 <div class="top-line">
                   <strong>{{$date.utc(date.date).local().format('ddd')}}, {{$date.utc(date.date).local().format("h:mm a")}} to {{$date.utc(date.date).add(1, 'hour').local().format("h:mm a")}}</strong>
                 </div>
                 <div class="bottom-line grey--text ">
                   <span>{{loadedUser.firstName}} </span>
                   <span>{{$date.utc(date.date).local().format("h:mm a")}} to {{$date.utc(date.date).add(1, 'hour').local().format("h:mm a")}}</span>
                 </div>
               </div>
               <div class="flex-grow-0 px-3"  @click.stop="removeDate(date, hidePopover)">
                 <v-icon>mdi-close-circle-outline</v-icon>
               </div>
             </div>
         </template>

         <template v-else>
           <v-row no-gutters align="stretch" justify="end" :class="{'mb-1' : $isUndefined(date) || $isEmpty(date), 'mb-3': !$isUndefined(date) || !$isEmpty(date)}">
             <!-- show accept/reject button -->

             <v-col cols="" >
               <div class="pa-2 rounded primary white--text d-flex align-center">
                 <div class="flex-grow-1">
                   <div class="subtitle-1">
                     <strong>{{$date.utc(date.date).local().format("MMM D")}}</strong>
                   </div>
                   <span>{{$date.utc(date.date).local().format("h:mm a")}} - {{$date.utc(date.date).add(1, 'hour').local().format("h:mm a")}}</span>
                 </div>
                 <div class="flex-grow-0 px-3"  @click.stop="removeDate(date, hidePopover)">
                   <v-icon>mdi-close-circle-outline</v-icon>
                 </div>

               </div>
             </v-col>

             <v-col cols="auto" class="ml-2 d-flex flex-column justify-end align-items-end">
                 <v-avatar size="30" :class="{'hidden' : $isUndefined(date) || $isEmpty(date)}"><v-img :src="localUser.photoURL_thumb"></v-img></v-avatar>
             </v-col>
           </v-row>
         </template>


        </div>
       </template>
      </date-picker>

      <!-- add new lines -->
      <div class="d-flex align-center mt-4" v-if="!$isUndefined(canASAP) || !$isEmpty(canASAP)">
        <v-btn
          v-if="dates.length == 0"
          class="text-sm text-indigo-600 font-semibold hover:text-indigo-500 px-2 h-8 focus:outline-none"
          @click.prevent="sendASAP"
          :class="{'primary white--text' : ifSendASAP}">
          <v-icon class="mr-2">mdi-clock-fast</v-icon> ASAP
        </v-btn>
        <span v-if="dates.length == 0" class="mx-4">or</span>
        <v-btn
          class="text-sm text-indigo-600 font-semibold hover:text-indigo-500 px-2 h-8 focus:outline-none"
          @click.prevent="addDate"
          >
          + Add Date
        </v-btn>
        <span v-if="dates.length > 0" class="secondary--text ml-4 body-2">*click date to edit time.</span>
      </div>
      <div class="d-flex mt-4 justify-end" v-else>
        <v-btn
          text color="primary"
          class="text-sm text-indigo-600 font-semibold hover:text-indigo-500 px-2 h-8 focus:outline-none"
          @click.prevent="addDate"
          >
          + Propose New Date
        </v-btn>
      </div>

  </div>

</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'chooseTime',
  components:{'date-picker': DatePicker},
  props:['loadedUser', 'localUser', 'canASAP'],
  watch:{
    dates:{
      handler:function(val){
        this.$emit('dates', val)
        if(val.length > 0){
          process.env.NODE_ENV === "development" ? console.log( 'selected date format???', this.$date.utc(val[0].date).format() ) : null;
          process.env.NODE_ENV === "development" ? console.log( 'selected date is UTC???', this.$date.utc(val[0].date).isUTC() ) : null;
        }
      },
      deep: true
    }
  },
  data: () => ({
    dialog:true,
    selected: {},
    ifSendASAP: false,
    dates:[],
    attrs:[
        {
          key: 'today',
          highlight: {
            color: 'purple',
            fillMode: 'outline',
          },
          dates: new Date()
        }
    ],
    theme: {
      color: 'blue',
    }

  }), //end data
  methods:{
    addDate() {
      let vThis = this;
      this.dates.push({
        date: vThis.nearestThirty(),
      });
      this.ifSendASAP = false;
      this.$emit('send-soon', false);
      // this.$nextTick(() => {
      //   const btn = this.$refs.time_button[this.$refs.time_button.length - 1];
      //   btn.click();
      // });
      setTimeout(() => {
        process.env.NODE_ENV === "development" ? console.log( 'click it', this.$refs.time_button[this.$refs.time_button.length - 1] ) : null;
        const btn = this.$refs.time_button[this.$refs.time_button.length - 1]
        btn.click();
      }, 500);
    },
    removeDate(date, hide) {
      this.dates = this.dates.filter((d) => d !== date);
      hide();
    },
    dateSelected(e, date, toggle) {
      process.env.NODE_ENV === "development" ? console.log( 'clicked for real', e ) : null;
      this.selected = date;
      toggle({ ref: e.target });
    },
    sendASAP(){
      let toggle = !this.ifSendASAP
      this.$emit('send-soon', toggle);
      this.ifSendASAP = toggle;
    },
    nearestThirty(){
      return this.$date().add(1, 'hour').startOf('hour').add(30, 'minute').format()
    },
    setupTime(){
      // this.addDate();
      // default local time
      process.env.NODE_ENV === "development" ? console.log( this.$date().format()  ) : null;
      // UTC mode
      process.env.NODE_ENV === "development" ? console.log( this.$date.utc().format() ) : null;
      // convert local time to UTC time
      process.env.NODE_ENV === "development" ? console.log( this.$date().utc().format() ) : null;

      process.env.NODE_ENV === "development" ? console.log( this.$date.utc().local().format() ) : null;


    }
  },
  mounted(){
    this.setupTime()
  }
};
</script>


<style lang="scss">
.choose-time{
  .date-container{
    border-radius: 6px;
    padding-bottom: -1px;
  }
  .dates{
    cursor:pointer;
    .date{
      // font-family: 'Nexa Text Book';

      .month{
        color: #aaa;
        font-size: 12px;
        line-height: 12px;
      }
      .day{
        font-size: 22px;
        line-height: 28px;
        color: #555;
      }
    }
    .top-line{
      font-size: 15px;
      line-height: 18px;
      color: #444;
    }
    .bottom-line{
      font-size: 13px;
      line-height: 20px;
    }
    svg{
      width: 24px;
    }
  }
  .vc-container{
    .is-today{
      .vc-highlights .vc-highlight{border-color: var(--v-secondary-base)!important}
      .vc-day-content{color: var(--v-secondary-base)}
    }
  }
}
</style>
